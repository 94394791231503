<template>
  <vx-card title="Product List" class="mt-base">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="productData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between"
        >
          <div style="float: left">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="order">Order No.</vs-th>
          <vs-th>Image</vs-th>
          <vs-th sort-key="name">Product Name</vs-th>
          <vs-th>Store Price</vs-th>
          <vs-th>Cost Price</vs-th>
          <vs-th>Stock Status</vs-th>
          <vs-th>Hide Status</vs-th>
          <vs-th>Total Sold (Last 12 Months)</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <draggable :list="data" @change="updateOrderNumber(data)" tag="tbody" class="cursor-pointer">
            <tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <td :data="tr.order">
                {{ tr.order ? tr.order : ''}}
              </td>
              <td v-if="!tr.images"></td>
              <td :data="tr.images.image" v-else>
                <div style="width: 100px; height: auto">
                  <img
                    class="img-fluid"
                    :src="tr.images.image"
                    alt=""
                  />
                </div>
              </td>
              <td :data="tr.name">
                {{ tr.name }}
              </td>
              <td :data="tr.compareAtPrice">
                ${{ (tr.compareAtPrice ? tr.compareAtPrice / 100 : 0).toFixed(2) }}
              </td>
              <td :data="tr.price">
                ${{ (tr.price / 100).toFixed(2) }}
              </td>
              <vs-td :data="tr.supplier">{{ tr.stock || 'N/A' }}</vs-td>
              <vs-td :data="tr.hideStatus">
                <p v-if="tr.hideStatus === true">Hidden</p>
                <p v-else>Not Hidden</p>
              </vs-td>
              <vs-td :data="tr.totalQuantity">
                {{tr.totalQuantity}}
              </vs-td>
              <td :data="tr._id">
                <vx-tooltip
                  text="Edit Product"
                  position="left"
                  style="display: inline-block; margin-right: 10px"
                >
                  <vs-button
                    type="border"
                    size="small"
                    color="success"
                    @click="editProduct(tr._id)"
                    class="mt-1"
                    >Edit</vs-button
                  >
                </vx-tooltip>
                <vx-tooltip
                  text="Remove Product"
                  position="left"
                  style="display: inline-block"
                >
                  <vs-button
                    type="border"
                    size="small"
                    color="danger"
                    @click="openDeletePopup(tr._id)"
                    icon-pack="feather"
                    icon="icon-trash"
                    class="mt-1"
                  ></vs-button>
                </vx-tooltip>
              </td>
              <vs-popup
                :active.sync="popUpDelete"
                title="Remove Product from Supplier"
              >
                <p align="center">
                  Are you sure you want to remove this Product from Supplier?
                </p>
                <br />
                <vs-row
                  vs-align="flex-start"
                  vs-type="flex"
                  vs-justify="left"
                  vs-w="12"
                  vs-mt="6"
                >
                  <vs-col vs-type="flex" vs-align="left" vs-w="6">
                    <div class="w-full m-5" align="right">
                      <vs-button
                        size="lg"
                        color="primary"
                        @click="deleteDetailHandler(popupData.id)"
                        >Yes</vs-button
                      >
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-align="left" vs-w="6">
                    <div class="w-full m-5">
                      <vs-button
                        size="lg"
                        color="danger"
                        @click="popUpDelete = false"
                        >Cancel</vs-button
                      >
                    </div>
                  </vs-col>
                </vs-row>
              </vs-popup>
            </tr>
          </draggable>
        </template>
      </vs-table>
      <span class="mr-2">
        {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
        }}
        -
        {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
            ? dataTableParams.page * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}
      </span>
      <div class="pagination-div" v-if="serverResponded">
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "ProductListBySupplier",
  components: {
    vSelect,
    draggable,
  },
  props: {
    supplierId: {
      type: String,
    },
  },
  data() {
    return {
      id: this.supplierId,
      isMounted: false,
      currentPage: 1,
      totalDocs: 0,
      page: 1,
      noDataText: "Loading...",
      dataTableParams: {
        search: "",
        sort: "order",
        dir: "asc",
        page: 1,
        limit: 100,
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false,
      awaitingSearch: null,
      productData: [],
      popUpDelete: false,
      popupData: {
        id: "",
      },
    };
  },
  methods: {
    ...mapActions("supplier", [
      "getSupplierProductList",
      "removeSupplierFromProduct",
    ]),
    ...mapActions("storeProducts", [
      "updateProductsOrderNumber",
    ]),
    async updateOrderNumber(props){
      if(props){
        this.$vs.loading();
        const payload = props.map((item, index) => {
          return {
            _id: item._id,
            order: parseInt(index+1),
          }
        });
        const res = await this.updateProductsOrderNumber(payload);
        if(res.status === 200){
          this.$vs.notify({
            title: "Success",
            text: "Product Order Updated",
            color: "success",
          });
          this.fetchProductListBySupplier(this.id);
        }
        this.$vs.loading.close();
      }
    },
    async fetchProductListBySupplier(id) {
      this.$vs.loading();
      let self = this;
      let info = {
        dataTableParams: this.dataTableParams,
        id: this.id,
      };
      await this.getSupplierProductList(info).then((res) => {
        self.productData = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total || 0;
        self.page = res.data.data.pagination.page;
        self.currentPage = res.data.data.pagination.page;
        self.noDataText = "No Product Available";
        self.serverResponded = true;
        this.$vs.loading.close();
      }).catch((err) => {
        this.$vs.loading.close();
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchProductListBySupplier(this.id);
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchProductListBySupplier(this.id);
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchProductListBySupplier(this.id);
    },
    openDeletePopup(id) {
      this.popupData.id = id;
      this.popUpDelete = true;
    },
    editProduct(id) {
      this.$router.push({
        name: "SuperAdminStoreProductsEdit",
        params: { id },
      });
    },
    async deleteDetailHandler(id) {
      const data = {
        supplierId: this.id,
        productId: id,
      };
      await this.removeSupplierFromProduct(data)
        .then((res) => {
          this.popUpDelete = false;
          this.fetchProductListBySupplier(this.id);
          this.$vs.notify({
            title: "Success",
            text: "Product Removed successfully.",
            color: "success",
          });
        })
        .catch((err) => {
          this.popUpDelete = false;
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger",
          });
        });
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.fetchProductListBySupplier(this.id);
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchProductListBySupplier(this.id);
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  async created() {
    this.id = this.supplierId;
    await this.fetchProductListBySupplier(this.id);
  },
};
</script>

<style scoped>
</style>
